<template>
  <div class="main-home">
    <section id="home" class="main-home__slide slide carousel" :style="backgroundStyle">
      <div class="carousel-text">
        <span class="attention-script">
          <img :src="require('@/assets/images/attention_script.png')" class="upstart-text" alt="Attention" />
        </span>
        <h1>ATTENTION MUST BE EARNED.<br />
          WE SHAKE S**T UP!</h1>
      </div>
    </section>

    <section class="main-home__slide slide-3 red-menu" id="about-us">
      <div class="slide-3__upstart-container">
        <h1>Welcome to the home of</h1>
        <img :src="require('@/assets/images/upstart-bg.png')" class="upstart-text" alt="Upstart" />
        <img :src="require('@/assets/images/upstart-thinking.svg')" class="upstart-thinking-text"
          alt="Upstart Thinking" />
        <img :src="require('@/assets/images/thinking.png')" class="thinking-text" alt="Thinking" />
      </div>

      <div class="slide-3__definition">
        <p>We’ve been a ‘start-up’ since 1988 - Constantly challenging and punching for some of the world’s most
          exciting brands. Through insight-driven creativity, we ‘upstart’ brand experiences to earn attention, energise
          fandom and drive numbers you can’t argue with. </p>
      </div>


      <div class="showreel-container" ref="videoContainer">
        <PlayIcon v-if="!isExpanded" class="play-button" @click="playVideoOverlay"></PlayIcon>
        <video id="video1" v-if="!isExpanded" :poster="require('@/assets/images/poster-sr.png')" muted loop playsinline
          autoplay :src="require('@/assets/video/landing_page_short.mp4')" type="video/mp4">
          <source :src="require('@/assets/video/landing_page_short.mp4')" type="video/mp4" />
        </video>

        <video id="video2" v-show="isExpanded" poster="https://ignisshowreel.s3.eu-west-1.amazonaws.com/poster.png"
          playsinline @ended="handleSecondVideoEnd" @click="togglePlayPause" ref="video2"
          src="https://ignisshowreel.s3.eu-west-1.amazonaws.com/ignis_sizzle_reel_2025_1080p.mp4" type="video/mp4">
          <source src="https://ignisshowreel.s3.eu-west-1.amazonaws.com/ignis_sizzle_reel_2025_1080p.mp4"
            type="video/mp4" />
        </video>


        <div class="top-right"></div>
        <div v-if="isExpanded" class="close-video-button"><img :src="require('@/assets/images/close_button.svg')"
            @click="closeVideoOverlay" /></div>
      </div>
    </section>

    <v-overlay z-index="12000" :value="overlay" @click="closeVideoOverlay">
    </v-overlay>

    <section class="main-home__slide slide white-menu">
      <div class="slide__container challenger-thinking">
        <div class="slide__container__work">
          <div class="slide__title">
            <span class="uncomfortably_script">
              <img :src="require('@/assets/images/uncomfortably_script.png')" />
            </span>
            <h2>ARE YOU SITTING<br />
              UNCOMFORTABLY?</h2>
          </div>
          <p>
            That’s where we begin. Unjoining the dots of a brand and its challenges, to create counter-conventional
            brand experiences that scream innovation, encourage participation and satisfy need.
          </p>

          <a href="/our-work" class="fancy-link work">OUR WORK <span class="inline-icon">
              <img :src="require('@/assets/images/cricle-arrow-right.svg')" alt="circle arrow" /></span>
          </a>
        </div>
      </div>
    </section>

    <section class="main-home__slide slide black-bg">
      <div class="slide__container">
        <div class="slide__container__deliver">
          <div class="slide__title">
            <span class="experience_script">
              <img :src="require('@/assets/images/experience_home_script.png')" alt="Experience" />
            </span>
            <h2>BE AN EXPERIENCE<br />
              THAT MATTERS</h2>
          </div>
          <p>
            The truth is, we no longer define a brand’s relevance by its product or service but by the experience it
            gives us. We help our clients to ensure that purposeful, social and intuitive experiences are at the very
            heart of every consumer interaction.
          </p>
          <button class="fancy-link">WE DELIVER</button>
        </div>
        <div class="panel-deliver-thumbs">
          <div class="deliver-thumb-1">
            <img :src="require('@/assets/images/conversion_thumb_text.png')" alt="Conversion" />
          </div>
          <div class="deliver-thumb-2">
            <img :src="require('@/assets/images/impact_thumb_text.png')" alt="Impact" />
          </div>
          <div class="deliver-thumb-3">
            <img :src="require('@/assets/images/purpose_thumb_text.png')" alt="Purpose" />
          </div>
          <div class="deliver-thumb-4">
            <img :src="require('@/assets/images/fandom_thumb_text.png')" alt="Fandom" />
          </div>
        </div>
      </div>

    </section>

    <section class="main-home__slide slide-6">
      <div class="slide-6__container">
        <div class="slide-6__container__title">
          <span class="clients_script">
            <img :src="require('@/assets/images/clients script.png')" alt="Clients" />
          </span>
          <h1>Our Clients</h1>
        </div>
        <div class="slide-6__container__logos">
          <div class="slide-6__container__logos__row-1">
            <div class="logo-box pepsi">
              <img :src="require('@/assets/images/client-logos/Pepsi@2x.png')" alt="Pepsi" class="bulls-text-overlay" />
            </div>
            <div class="logo-box asahi">
              <img :src="require('@/assets/images/client-logos/Asahi@2x.png')" alt="Asahi" class="bulls-text-overlay" />
            </div>
            <div class="logo-box">
              <img :src="require('@/assets/images/client-logos/Marriott Logo@2x.png')" alt="Marriott"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box delonghi">
              <img :src="require('@/assets/images/client-logos/delonghi@2x.png')" alt="DeLonghi"
                class="bulls-text-overlay" />
            </div>
          </div>

          <div class="slide-6__container__logos__row-2">
            <div class="logo-box peroni">
              <img :src="require('@/assets/images/client-logos/Peroni Logo@2x.png')" alt="Peroni"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box">
              <img :src="require('@/assets/images/client-logos/britvic.png')" alt="Britvic"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box cleanco">
              <img :src="require('@/assets/images/client-logos/cleanco.png')" alt="CleanCo"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box hilton">
              <img :src="require('@/assets/images/client-logos/hilton.png')" alt="Hilton" class="bulls-text-overlay" />
            </div>
          </div>

          <div class="slide-6__container__logos__row-3">
            <div class="logo-box">
              <img :src="require('@/assets/images/client-logos/Campari@2x.png')" alt="Campari"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box lipton">
              <img :src="require('@/assets/images/client-logos/lipton.png')" alt="Lipton" class="bulls-text-overlay" />
            </div>
            <div class="logo-box">
              <img :src="require('@/assets/images/client-logos/Moxy Hotels@2x.png')" alt="Moxy Hotels"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box london-pride">
              <img :src="require('@/assets/images/client-logos/London Pride@2x.png')" alt="London Pride"
                class="bulls-text-overlay" />
            </div>
          </div>
        </div>

        <div class="slide-6__container__logos_mobile">
          <div class="slide-6__container__logos__row-1">
            <div class="logo-box pepsi">
              <img :src="require('@/assets/images/client-logos/Pepsi@2x.png')" alt="Pepsi" class="bulls-text-overlay" />
            </div>
            <div class="logo-box asahi">
              <img :src="require('@/assets/images/client-logos/Asahi@2x.png')" alt="Asahi" class="bulls-text-overlay" />
            </div>
            <div class="logo-box">
              <img :src="require('@/assets/images/client-logos/Marriott Logo@2x.png')" alt="Marriott"
                class="bulls-text-overlay" />
            </div>
          </div>

          <div class="slide-6__container__logos__row-2">
            <div class="logo-box delonghi">
              <img :src="require('@/assets/images/client-logos/delonghi@2x.png')" alt="DeLonghi"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box peroni">
              <img :src="require('@/assets/images/client-logos/Peroni Logo@2x.png')" alt="Peroni"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box">
              <img :src="require('@/assets/images/client-logos/britvic.png')" alt="Britvic"
                class="bulls-text-overlay" />
            </div>
          </div>

          <div class="slide-6__container__logos__row-3">
            <div class="logo-box cleanco">
              <img :src="require('@/assets/images/client-logos/cleanco.png')" alt="CleanCo"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box hilton">
              <img :src="require('@/assets/images/client-logos/hilton.png')" alt="Hilton" class="bulls-text-overlay" />
            </div>
            <div class="logo-box london-pride">
              <img :src="require('@/assets/images/client-logos/London Pride@2x.png')" alt="London Pride"
                class="bulls-text-overlay" />
            </div>
          </div>

          <div class="slide-6__container__logos__row-4">
            <div class="logo-box campari">
              <img :src="require('@/assets/images/client-logos/Campari@2x.png')" alt="Campari"
                class="bulls-text-overlay" />
            </div>
            <div class="logo-box lipton">
              <img :src="require('@/assets/images/client-logos/lipton.png')" alt="Lipton" class="bulls-text-overlay" />
            </div>
            <div class="logo-box moxy">
              <img :src="require('@/assets/images/client-logos/Moxy Hotels@2x.png')" alt="Moxy Hotels"
                class="bulls-text-overlay" />
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { gsap } from 'gsap';
import PlayIcon from '@/components/svg/PlayIcon';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from "@/plugins/gsap/DrawSVGPlugin";
import ScrambleTextPlugin from "@/plugins/gsap/ScrambleTextPlugin";
import { Observer } from 'gsap/Observer';
import { ref, reactive, computed, onMounted, onBeforeMount, nextTick } from 'vue';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(Observer);

export default {
  name: 'HomePage',
  components: {
    PlayIcon,
  },
  setup() {
    const jsonld = reactive({
      "@context": "http://schema.org",
      "@type": "VideoObject",
      "name": "The Ignis Sizzle Reel",
      "description": "Are you sitting uncomfortably, Then that's where we begin. This video proudly showcases the collaborative efforts between Ignis and the following esteemed brands, as they've worked together to enhance their branding and marketing strategies.",
      "thumbnaillUrl": "https://ignisshowreel.s3.eu-west-1.amazonaws.com/poster.png",
      "uploadDate": "2024-01-17",
      "duration": "PT1H4M0S",
      "contentUrl": "https://www.ignis.co.uk/work",
      "embedUrl": "https://ignisshowreel.s3.eu-west-1.amazonaws.com/ignis_sizzle_reel_2025_1080p.mp4",
      "interactionCount": "5000",
      "expires": "2024-01-17",
    });

    const scrollTween = ref(null);
    const isFullscreen = ref(false);
    const isPlaying = ref(true);
    const isPlayingShowReel = ref(false);
    const playedSecondVideoOnce = ref(false);
    const isMuted = ref(true);
    const isSecondVideoPlaying = ref(false);
    const scrollPaused = ref(false);
    const videoStateClass = ref('pause-cursor');
    const videoStateClass2 = ref('play-cursor');
    const videoTriggeredByLink = ref(false);
    const overlay = ref(false);
    const isExpanded = ref(false);
    const backgroundImages = [
      require('@/assets/images/hero_asahi.png'),
      require('@/assets/images/hero_campari.png'),
    ];
    const selectedImage = ref('');

    const backgroundStyle = computed(() => {
      return {
        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 60%), url(${selectedImage.value})`,
      };
    });

    // Assign a random background image on created
    onBeforeMount(() => {
      const randomIndex = Math.floor(Math.random() * backgroundImages.length);
      selectedImage.value = `${backgroundImages[randomIndex]}`;
    });

    // Methods
    const playVideoOverlay = () => {
      if (scrollTween.value) scrollTween.value.kill();

      isExpanded.value = true;

      nextTick(() => {
        const video2 = document.querySelector('#video2');
        if (video2) {
          video2.currentTime = 0; // Reset video2 to start
          video2.play();
        }
      });

      scrollTween.value = gsap.timeline();

      scrollTween.value.set(document.querySelector('#videoContainer'), { zIndex: 16000 });

      // Step 1: Expand Width First
      scrollTween.value.to(document.querySelector('#videoContainer'), {
        duration: 0.6,
        width: "70vw",  // Expand width
        ease: "power2.out",
      });

      // Step 2: Expand Height After 1 Second Delay (Top & Bottom Adjust Together)
      scrollTween.value.to(document.querySelector('#videoContainer'), {
        duration: 0.6,
        height: "60vh",  // Expand height
        ease: "power2.out",
      }, "+=0.3"); // 1-second delay after width animation

      overlay.value = true;
    };

    const closeVideoOverlay = () => {
      if (!isExpanded.value) return; // Prevent unnecessary calls

      isExpanded.value = false;
      overlay.value = false;

      const video2 = document.querySelector('#video2');
      if (video2) {
        video2.pause();
        video2.currentTime = 0; // Reset video2 to start
      }
      // Create a new closing animation
      gsap.to(document.querySelector('#videoContainer'), {
        duration: 0.6,
        width: "710px", // Reset width to original
        height: "400px", // Reset height to original
        ease: "power2.inOut",
      });
    };

    const handleSecondVideoEnd = () => {
      closeVideoOverlay(); // When video2 ends, return to video1
    };

    const togglePlayPause = () => {
      const video2 = document.querySelector('#video2');
      if (video2.paused) {
        video2.play();
      } else {
        video2.pause();
      }
    };

    const toggleFullscreen = async () => {
      const videoElement = document.getElementById('video2');
      try {
        if (videoElement.requestFullscreen) {
          await videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          await videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitEnterFullscreen) {
          await videoElement.webkitEnterFullscreen();
        } else if (videoElement.msRequestFullscreen) {
          await videoElement.msRequestFullscreen();
        }
        isFullscreen.value = true;
      } catch (error) {
        console.error('Fullscreen failed:', error);
      }
    };

    const videoExitedFullscreen = (videoElement) => {
      isFullscreen.value = false;
      isPlaying.value = !videoElement.paused;
    };

    const ensureWhiteMenuInViewport = () => {
      const whiteMenu = document.querySelector('.white-menu');
      const menuLinks = document.querySelectorAll('.menu a');
      const logoPath = document.querySelectorAll('.logo path');
      const burgerMenu = document.querySelectorAll('.burger-menu path');

      if (!whiteMenu || !menuLinks.length) {
        console.error('Elements not found in the DOM.');
        return;
      }

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              menuLinks.forEach((link) => {
                link.style.setProperty('color', '#FFFFFF');
              });
              logoPath.forEach(path => {
                path.setAttribute('fill', '#FFFFFF');
              });
              burgerMenu.forEach(path => {
                path.setAttribute('fill', '#FFFFFF');
              });
            } else {
              menuLinks.forEach((link) => {
                link.style.setProperty('color', '#E20034');
              });
              logoPath.forEach(path => {
                path.setAttribute('fill', '#E20034');
              });
              burgerMenu.forEach(path => {
                path.setAttribute('fill', '#E20034');
              });
            }
          });
        },
        {
          threshold: 1.0,
        }
      );

      observer.observe(whiteMenu);
      // Initial check (for cases where the element might already be in the viewport on load)
      requestAnimationFrame(() => {
        if (isInViewport(whiteMenu)) {
          menuLinks.forEach((link) => {
            link.style.setProperty('color', '#FFFFFF');
          });
          logoPath.forEach(path => {
            path.setAttribute('fill', '#FFFFFF');
          });
          burgerMenu.forEach(path => {
            path.setAttribute('fill', '#FFFFFF');
          });
        } else {
          menuLinks.forEach((link) => {
            link.style.setProperty('color', '#E20034');
          });
          logoPath.forEach(path => {
            path.setAttribute('fill', '#E20034');
          });
          burgerMenu.forEach(path => {
            path.setAttribute('fill', '#E20034');
          });
        }
      });
    };

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    // Mounted lifecycle hook
    onMounted(() => {
      const videoElement = document.getElementById('video2');
      videoElement.addEventListener('fullscreenchange', () => videoExitedFullscreen(videoElement));
      videoElement.addEventListener('webkitendfullscreen', () => videoExitedFullscreen(videoElement));

      nextTick(() => {
        ensureWhiteMenuInViewport();
      });

      // Add scroll triggers for animations (using GSAP)
      // const menuLinks = document.querySelectorAll('.menu a');
      // const whiteMenu = document.querySelector('.white-menu');
      // const logoPath = document.querySelectorAll('.logo path');
      // const burgerMenu = document.querySelectorAll('.burger-menu path');

      // Handle GSAP ScrollTrigger logic here...
    });

    return {
      jsonld,
      scrollTween,
      isFullscreen,
      isPlaying,
      isPlayingShowReel,
      playedSecondVideoOnce,
      isMuted,
      isSecondVideoPlaying,
      scrollPaused,
      videoStateClass,
      videoStateClass2,
      videoTriggeredByLink,
      overlay,
      isExpanded,
      backgroundStyle,
      playVideoOverlay,
      closeVideoOverlay,
      handleSecondVideoEnd,
      togglePlayPause,
      toggleFullscreen,
      ensureWhiteMenuInViewport,
      isInViewport,
    };
  },
};
</script>


<style lang="scss">
@function best-text-color($bg-color, $light-color, $dark-color) {
  $lightness: lightness($bg-color);
  @return if($lightness > 50%, $dark-color, $light-color);
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}

.slide-1__video-wrapper {
  display: none;
  visibility: hidden;
}

.v-main__wrap {
  position: unset;
}

.play-cursor {
  cursor: url('../assets/images/play.svg'), pointer;
  transition: all 0.3 ease-in-out;
}

.pause-cursor {
  cursor: url('../assets/images/pause.svg'), pointer;
  transition: all 0.3 ease-in-out;
}

.brands-script,
.passion-script,
.attitude-script,
.culture-script {
  fill: #ffffff;
  opacity: 1;
}

.mask {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}

.main-home {
  margin: 0;
  background-color: #000;

  .fade-in {
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  .fade-in.show {
    opacity: 1;
  }

  &__slide {
    width: 100%;
    display: flex;
    height: 100vh;
    min-height: 1024px;
    position: relative;

    @media screen and (max-width: 768px) {
      min-height: unset !important;
      height: unset;
    }

    &-static-header-desktop {
      position: absolute;
      top: calc((100vh - 640px) / 4);
      left: 50vw;
      transform: translateX(-50%);
      width: 300px;
      z-index: 100;

      @media screen and (max-height: 830px) {
        //top: 20px;
        position: fixed;
        top: 62px;
      }

      h2 {
        font-family: $font-stack-bison;
        font-size: 4.5em;
        line-height: 1;
        text-align: center;
        color: var(--color-black);
        width: 100%;

        @media screen and (max-width: 1024px) {
          display: none;
          font-size: 2.5em;
        }
      }
    }

  }


  #home {
    min-height: 100dvh !important;
  }

  .slide-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    perspective: 1000px;
    transform-style: preserve-3d;
    color: #fff;


    #video2 {
      display: none;
    }

    &__video-wrapper {
      position: relative;
      width: 100%;
      height: 100dvh;
      min-height: 834px;

      @media screen and (max-width: 1024px) {
        min-height: unset;
      }

      &__container {
        &__video {
          object-fit: cover;
          width: 100%;
          height: 100%;
          padding: 0;
          position: absolute;
        }

        &__controls {
          position: absolute;
          bottom: 60px;
          right: 10px;
          display: flex;
          gap: 5px;
          z-index: 10;
        }

        &__controls-play {
          position: absolute;
          left: calc(50% - 50px);
          top: 50%;
          transform: translate(-50% -50%);
          z-index: 10;
          height: fit-content;
        }
      }
    }

    .play-pause-btn,
    .mute-unmute-btn,
    .fullscreen-btn {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      border: none;
      cursor: pointer;
      font-size: 22px;
      border-radius: 5px;
    }

    .play-btn {
      background-color: unset;
      color: #E20034;
      font-size: 4em;
      height: fit-content;
      // mix-blend-mode: multiply;

      svg {
        fill-opacity: 1;
      }

    }

    .play-btn:hover,
    .play-pause-btn:hover,
    .mute-unmute-btn:hover,
    .fullscreen-btn:hover {
      cursor: none;
      background-color: rgba(0, 0, 0, 1);
    }

  }

  .slide {
    background-color: #E20034;
    text-align: center;
    display: block;
    color: #ffffff;

    &.black-bg {
      background-color: #000;
    }

    &.carousel {
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: end;
      justify-content: center;

      .carousel-text {
        padding: 0 0 5%;
        position: relative;
        z-index: 2;
        transition: all 0.3s;

        .attention-script {
          position: absolute;
          left: -110px;
          top: -72px;
          z-index: -1;

          img {
            width: 460px;
          }
        }

        h1 {
          font-size: 6.5em;
          line-height: 1;
        }

        @media screen and (max-width: 1024px) {

          .attention-script {
            width: 50%;
            left: 10px;
            top: -32px;
          }

          h1 {
            font-size: 3.4em;
            padding: 0 30px;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        // background-position: 71%;

        .carousel-text {
          padding: 0 0 60px;
        }

        align-items: end;

      }
    }

    &__title {
      width: 100%;
      padding: 0;
      margin-bottom: 60px;
      position: relative;
      z-index: 2;

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }

      h2 {
        font-weight: 300;
        color: #ffffff;
        font-size: 5em;
        line-height: 0.775em;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 3.5em
        }

      }
    }

    .uncomfortably_script {
      position: absolute;
      top: -130px;
      left: 180px;
      z-index: -1;

      img {
        width: 610px;

      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .experience_script {
      position: absolute;
      top: -140px;
      left: 190px;
      z-index: -1;

      img {
        width: 540px;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    p {
      font-size: 1.15em;
      color: #ffffff;
      line-height: 1.6;
      font-weight: 300;
      text-align: center;
      max-width: 660px;
    }

    .fancy-link {

      font-family: $font-stack-bison;
      margin: 40px 0 0 0;
      font-size: 2em;
      color: var(--color-red);

      &.work {
        color: #ffffff;
      }

      img {
        width: 40px;
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      min-height: 1024px;

      &__work {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 960px;
        margin: 212px auto 0;
        height: calc(100vh - 212px);
        position: relative;

        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 30px 0 0;
          margin: 40px auto 0;
        }

        .category_video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          video {
            filter: brightness(0.6);
            width: 456px;
            opacity: 0.95;
          }

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }

        @media screen and (max-width: 1024px) {
          .category_video_mobile {
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);

            video {
              filter: brightness(0.6);
              opacity: 1;
            }
          }
        }


        @media screen and (min-width: 1025px) {
          .category_video_mobile {
            display: none;
          }
        }

        &-text {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          color: #ffffff;
          font-size: 3em;
          line-height: 1;
          margin-bottom: 0.8em;
          padding: 0 0 0 0.4em;
          letter-spacing: 0.4em;
          width: 100%;
          // min-height: 100px;
          direction: rtl;

          &.slide-in {
            opacity: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }


          :first-letter {
            letter-spacing: 0;
            display: unset !important;
          }
        }

        .fancy-link {
          display: none;
        }
      }

      &__deliver {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 960px;
        margin: 0 auto;
        position: relative;

        .category_video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          video {
            filter: brightness(0.6);
            width: 456px;
            opacity: 0.95;
          }

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }

        .slide__title {
          margin-bottom: 30px;
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
          padding: 30px 0 0 0;

          .category_video_mobile {
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);

            video {
              filter: brightness(0.6);
              opacity: 1;
            }
          }

          .fancy-link {
            margin: 0;
          }
        }


        @media screen and (min-width: 1025px) {
          .category_video_mobile {
            display: none;
          }
        }

        &-text {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          color: #ffffff;
          font-size: 3em;
          line-height: 1;
          margin-bottom: 0.8em;
          padding: 0 0 0 0.4em;
          letter-spacing: 0.4em;
          width: 100%;
          // min-height: 100px;
          direction: rtl;

          &.slide-in {
            opacity: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }


          :first-letter {
            letter-spacing: 0;
            display: unset !important;
          }
        }

      }

      @media screen and (max-width: 1024px) {
        height: 100%;
        min-height: unset;
        padding: 30px;

      }

      &.challenger-thinking {
        height: 100vh;
        min-height: unset;

        @media screen and (max-width: 768px) {
          height: calc(100vh - 112px);
        }
      }

    }

    .panel-deliver {
      &-thumbs {
        display: flex;
        justify-content: center;
        gap: 20px;
        max-width: 960px;
        width: 100%;
        margin: 50px auto 0;
        z-index: 1;

        @media screen and (max-width: 768px) {
          padding: 30px 30px;
          margin: 0;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }


        div {
          width: calc(100% / 4);
          max-width: calc(100% / 4);
          height: 100%;
          display: flex;
          align-items: end;
          justify-content: center;
          position: relative;

          @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            height: -webkit-fit-content;
            padding-bottom: 5px !important;
          }

          img {
            height: 30%;

            @media screen and (max-width: 768px) {
              height: 50px;
            }
          }
        }


        .deliver-thumb-1 {
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 40%),
            url('@/assets/images/home_thumb_1.png');
          background-size: cover;
          background-repeat: no-repeat;
          aspect-ratio: 1/1;
          padding-bottom: 10px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: #00FF00;
          }

          &::before {
            width: 12px;
            height: 60%;
            left: -12px;
            top: 0;
          }

          &::after {
            width: calc(60% + 12px);
            height: 12px;
            left: -12px;
            top: -12px;
          }
        }

        .deliver-thumb-2 {
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 40%),
            url('@/assets/images/home_thumb_2.png');
          background-size: cover;
          background-repeat: no-repeat;
          padding-bottom: 10px;
          aspect-ratio: 1/1;
        }

        .deliver-thumb-3 {
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 40%), url('@/assets/images/home_thumb_3.png');
          background-size: cover;
          background-repeat: no-repeat;
          padding-bottom: 10px;
          aspect-ratio: 1/1;
        }

        .deliver-thumb-4 {
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 40%), url('@/assets/images/home_thumb_4.png');
          background-size: cover;
          background-repeat: no-repeat;
          padding-bottom: 10px;
          aspect-ratio: 1/1;

          &::before,
          &::after {
            content: "";
            position: absolute;
            background-color: #00FF00;
          }

          &::before {
            width: 12px;
            height: 60%;
            right: -12px;
            bottom: 0;
          }

          &::after {
            width: calc(60% + 12px);
            height: 12px;
            right: -12px;
            bottom: -12px;
          }
        }
      }
    }

    // @media screen and (max-width: 432px) {
    //   height: 667px;
    // }
  }

  .slide-3 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: 400;
    font-style: normal;
    position: relative;
    padding: 0 30px 200px;
    transition: all 0.3 ease-in;

    .close-video-button {
      position: absolute;
      top: -50px;
      right: -50px;
      z-index: 310000;
      width: 30px !important;
      height: 30px !important;
      cursor: pointer;
    }

    .play-button {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 30001;
      opacity: 0.9;
      width: 60px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }


    .showreel-container {
      position: absolute;
      z-index: 30000;
      bottom: -200px;
      height: 400px;
      width: 710px;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 768px) {
        bottom: -100px;
        height: 200px;
        width: 360px;
      }

      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        padding: 0;
      }

      &::before,
      &::after,
      .top-right::before,
      .top-right::after {
        content: "";
        position: absolute;
        background-color: #00FF00;
      }

      &::before {
        width: 16px;
        height: 50%;
        left: -16px;
        bottom: 0;
      }

      &::after {
        width: calc(50% + 16px);
        height: 16px;
        left: -16px;
        bottom: -16px;
      }

      .top-right::before {
        width: 16px;
        height: 50%;
        right: -16px;
        top: 0;
      }

      .top-right::after {
        width: calc(50% + 16px);
        height: 16px;
        right: -16px;
        top: -16px;
      }
    }

    &__upstart-container {
      position: relative;
      width: 100%;
      max-width: 640px;
      aspect-ratio: 50 / 32.38;
      margin: 0;

      h1 {
        font-family: $font-stack-bison;
        position: absolute;
        left: 20px;
        top: 30px;
        font-size: 2em;

        @media screen and (max-width: 768px) {
          font-size: 1.5em;
          left: 0px;
          top: 30px;
        }

        @media screen and (max-width: 432px) {
          font-size: 1.2em;
          left: 10px;
          top: 20px;
        }
      }


    }

    .upstart-text,
    .upstart-thinking-text,
    .upstart-thinking-outline-1,
    .upstart-thinking-outline-2 {
      position: absolute;
      bottom: unset !important;
      width: 100%;
    }

    .upstart-thinking-text {
      position: absolute;
      z-index: 10;
      left: 0;
    }

    .thinking-text {
      position: absolute;
      left: 0;
      z-index: 10;
      width: 100%;
    }

    &__word {
      display: block;
      color: #000;
      font-weight: 800;
      font-size: 3.4em;
      text-align: center;

      span {
        font-weight: normal;
        font-style: italic;
        font-weight: 200;
      }

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 1024px) {
        font-size: 3.4em;
      }

      @media screen and (max-width: 768px) {
        font-size: 3em;
      }

      @media screen and (max-width: 432px) {
        font-size: 1.8em;
      }

    }

    &__definition {
      display: block;
      max-width: 650px;
      aspect-ratio: 660 / 108;
      transition: all 0.3 ease-in !important;

      p {
        font-size: 1.15em;
        color: #000;
        font-weight: 300;
        text-align: center;
      }


      @media screen and (max-width: 768px) {
        width: 100%;
        // padding: 20px 40px;
      }
    }

    @media screen and (max-width: 432px) {
      padding: 10% 30px;
      justify-content: start;
      height: 667px;

    }
  }

  .slide-4 {
    width: 400vw;
    height: 100vh;
    display: flex;
    z-index: 2;
    flex-wrap: nowrap;
    position: relative;
    overflow: hidden;
    display: none;
    visibility: hidden;

    .panel {
      position: relative;
      box-sizing: border-box;
      width: 100vw;
      height: 100vh;
      min-height: 834px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &__container {
        position: relative;
        max-width: 1025px;
        height: 100%;
        max-height: 640px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
      }

      @media screen and (max-width: 1024px) {
        height: 100vh;
        display: flex;
        padding: 0;
      }
    }

    .panel-brands {
      background-color: $panel-brands-bg-color;

      &__text-container {
        width: 100%;
        height: -webkit-fill-available;
        position: relative;
      }

      &__brand-uppercase {
        width: 100%;
        color: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        padding: 0;

        img {
          width: 95vw;
          margin: 0 auto;
        }

        @media screen and (max-width: 1024px) {
          width: 130vw;

          img {
            width: 100%;
          }
        }
      }

      &__brand-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        z-index: 3;

        @media screen and (max-width: 1024px) {
          width: 332px;
        }
      }

      &__brand-thumbs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        z-index: 2;
        gap: 20px;

        .parallax-container {
          overflow: hidden;
          aspect-ratio: 1 / 1 !important;
          min-width: calc(70% / 4);
          max-width: calc(80% / 4);

          img {
            min-width: 100%;
            max-width: 100%;
          }
        }

        img,
        video {
          min-width: calc(70% / 4);
          max-width: calc(80% / 4);
        }

        @media screen and (max-width: 1024px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          height: calc(100% - 200px);
          width: 310px;
          gap: 10px;
          margin: 0 auto;
          aspect-ratio: 1/1;
          align-content: center;
          justify-items: center;

          img {
            width: 100% !important;
            max-width: 100%;
          }
        }
      }
    }

    .panel-passion {
      padding: 0;

      &__passion-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 50%;

        @media screen and (max-width: 1024px) {
          top: 30%;
          width: 322px;
        }
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 1024px) {
          display: block;
          width: 100vw;
        }

        &__col-2 {
          margin-left: 30px;
          margin-right: 30px;

          @media screen and (max-width: 1024px) {
            position: absolute !important;
            top: 0;
            height: calc(100%) !important;
            width: 100%;
          }
        }

        &__col-1,
        &__col-3 {
          .passion-text-graphic {
            @media screen and (max-width: 1024px) {
              display: none !important;
            }
          }
        }


        .col {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          position: relative;

          :nth-child(2) {
            margin: 30px 0;
          }
        }

        .passion_thumb-1,
        .passion_thumb-2,
        .passion_thumb-3 {
          position: absolute;
          bottom: 0;
          -webkit-mask-image: url('../assets/images/thumbnail-mask.png');
          mask-image: url('../assets/images/thumbnail-mask.png');
          mask-repeat: no-repeat;

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }

        .passion_thumb-1 {
          overflow: hidden;
          right: 0;
          bottom: 140px;
          width: 45%;
          aspect-ratio: 1 / 1 !important;

          img {
            width: 100% !important;
          }
        }


        .passion_thumb-2 {
          video {
            width: 100%;

          }
        }

        .passion_thumb-3 {
          overflow: hidden;
          aspect-ratio: 1 / 1 !important;
          left: 0;
          width: 70%;

          img {
            width: 100% !important;
          }

          z-index:0;
        }

        .passion_thumb-container {
          position: absolute;
          bottom: 0;
          opacity: 0;
          display: none;

          &__thumb-2 {
            margin: 0 10px
          }

          @media screen and (max-width: 1024px) {
            opacity: 1;
            display: flex;
            align-items: end;
          }
        }
      }

      .main-home__slide-static-header h2 {
        margin-top: 30px;
      }

    }

    .panel-culture {
      flex-direction: column;
      overflow: hidden;

      &__culture-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 50%;

        @media screen and (max-width: 1024px) {
          width: 322px;
          top: 30%;
        }
      }

      &__culture-thumbs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        z-index: 2;
        bottom: 0;
        gap: 20px;

        .parallax-container,
        video {
          overflow: hidden;
          min-width: calc(70% / 4);
          max-width: calc(80% / 4);
        }

        :nth-child(2),
        :nth-child(4) {
          z-index: 1;
        }

        :nth-child(3) {
          z-index: 1;
        }

        .culture-title {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;

          @media screen and (max-width: 1024px) {
            width: 115%;
            top: -40px;
          }

          img {
            max-width: 100% !important;
          }

          .cult-title {
            margin-bottom: 20%;
            margin-right: 5px;
          }

          .ure-title {
            margin-top: 20%;
            margin-left: 5px;
          }
        }

        @media screen and (max-width: 1024px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          // grid-template-rows: repeat(2, 1fr);
          width: 310px;
          gap: 10px;
          margin: 0 auto;
          aspect-ratio: 1/1;
          align-content: end;
          justify-items: center;
          height: calc(100% - 20px);

          img,
          video {
            width: 100% !important;
            max-width: 100%;
            z-index: 1 !important;
          }
        }

        .culture-thumb-1,
        .culture-thumb-2,
        .culture-thumb-3,
        .culture-thumb-4 {
          @media screen and (max-width: 1024px) {
            bottom: 0%;
          }
        }
      }
    }


    .panel-attitude {

      &__text-container {
        width: 100%;
        height: -webkit-fill-available;
        position: relative;
      }

      &__attitude-uppercase {
        width: 100%;
        color: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        padding: 0;

        img {
          width: 95vw;
          margin: 0 auto;
        }
      }



      &__attitude-title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 50%;

      }

      &__attitude-thumbs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        bottom: 0;
        gap: 20px;

        :nth-child(1) {
          z-index: 8;
        }

        :nth-child(3) {
          z-index: 8;
        }

        .parallax-container,
        video {
          overflow: hidden;
          min-width: calc(70% / 4);
          max-width: calc(80% / 4);
        }
      }
    }

  }

  .slide-4-mobile {
    width: 100%;
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    height: 667px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: unset;

    .main-home__slide-static-header h2 {
      margin-top: 30px;
    }

    &.panel-brands {
      background-image: url('../assets/images/brands/brands_script.png');
      background-repeat: no-repeat;
      background-position: 50% 100%;

      .slide-4-mobile__container {
        position: relative;
        max-width: 1025px;
        height: 100vh;
        max-height: 600px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
        padding: 0 0 40px;

        .panel-brands {

          &__text-container {
            width: 100vw;
            height: -webkit-fill-available;
            position: relative;
            z-index: 1;
          }

          &__brand-uppercase {
            width: 100%;
            color: #000;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) !important;
            z-index: 1;
            padding: 0;

            img {
              height: 106px;
              margin: 0 auto;
            }
          }

          &__brand-title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 240px;
            z-index: 3;
          }

          &__brand-thumbs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: calc(100% - 200px);
            width: 310px;
            gap: 0;
            margin: 0 auto;
            aspect-ratio: 1/1;
            align-content: center;
            justify-items: center;

            img,
            video {
              width: 100% !important;
              max-width: 100%;
            }
          }
        }
      }
    }

    &.panel-passion {
      background-image: url('../assets/images/passion/passion_script.png');
      background-repeat: no-repeat;
      background-position: 50% 100%;

      .slide-4-mobile__container {
        position: relative;
        max-width: 1025px;
        height: 100vh;
        max-height: 600px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
        padding: 0;
        z-index: 5;

        .panel-passion {
          // background-color: #E20034;

          &__text-container {
            width: 100vw;
            height: -webkit-fill-available;
            position: relative;
          }

          &__passion-uppercase {
            width: 100%;
            color: #000;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) !important;
            z-index: 1;
            padding: 0;

            img {
              height: 106px;
              margin: 0 auto;
            }
          }

          &__passion-title-mobile {
            position: absolute;
            left: 50%;
            top: calc(50% + 5px);
            transform: translate(-50%, -50%);
            width: 280px;
            z-index: 3;

            svg {
              opacity: 1 !important;
              visibility: visible !important;
            }
          }

          &__passion-thumbs {
            height: calc(100% - 200px);
            width: 310px;
            margin: 0 auto;
            aspect-ratio: 1/1;
            align-content: center;
            justify-items: center;

            img,
            video {
              width: 100% !important;
              max-width: 100%;
            }
          }
        }
      }
    }

    &.panel-culture {
      // background-color: #E20034;
      flex-direction: column;
      overflow: hidden;

      .slide-4-mobile__container {
        position: relative;
        max-width: 1025px;
        height: 100vh;
        max-height: 600px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
        padding: 0;
        background-color: #E20034;

        // .panel-culture {
        //   &__culture-title {
        //     position: absolute;
        //     left: 50%;
        //     top: 30%;
        //     transform: translate(-50%, -50%);
        //     z-index: 10;
        //     width: 322px;
        //   }

        //   &__culture-thumbs {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: center;
        //     width: 100%;
        //     z-index: 2;
        //     bottom: 0;
        //     gap: 20px;

        //     img {
        //       min-width: calc(70% / 4);
        //       max-width: calc(80% / 4);
        //     }

        //     :nth-child(2),
        //     :nth-child(4) {
        //       z-index: 1;
        //     }

        //     :nth-child(3) {
        //       z-index: -1;
        //     }

        //     .culture-title {
        //       position: absolute;
        //       bottom: 0;
        //       left: 50%;
        //       transform: translateX(-50%);
        //       display: flex;
        //       width: 115%;
        //       top: -40px;

        //       img {
        //         max-width: 100% !important;
        //       }

        //       .cult-title-mobile {
        //         margin-bottom: 20%;
        //         margin-right: 5px;
        //       }

        //       .ure-title-mobile {
        //         margin-top: 20%;
        //         margin-left: 5px;
        //       }
        //     }

        //     @media screen and (max-width: 1024px) {
        //       display: grid;
        //       grid-template-columns: repeat(2, 1fr);
        //       // grid-template-rows: repeat(2, 1fr);
        //       width: 310px;
        //       gap: 0;
        //       margin: 0 auto;
        //       aspect-ratio: 1/1;
        //       align-content: end;
        //       justify-items: center;
        //       height: calc(100% - 20px);

        //       img,
        //       video {
        //         width: 100% !important;
        //         max-width: 100%;
        //         z-index: 1 !important;
        //       }


        //     }


        //     .culture-thumb-1,
        //     .culture-thumb-2,
        //     .culture-thumb-3,
        //     .culture-thumb-4 {
        //       bottom: 0%;
        //     }
        //   }
        // }

        .panel-culture {

          &__text-container {
            width: 100vw;
            height: -webkit-fill-available;
            position: relative;
          }

          &__culture-uppercase {
            width: 100%;
            color: #000;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) !important;
            z-index: 1;
            padding: 0;

            img {
              height: 106px;
              margin: 0 auto;
            }
          }

          &__culture-title-mobile {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 280px;
            z-index: 3;
          }

          &__culture-thumbs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: calc(100% - 200px);
            width: 310px;
            gap: 0;
            margin: 0 auto;
            aspect-ratio: 1/1;
            align-content: center;
            justify-items: center;

            img,
            video {
              width: 100% !important;
              max-width: 100%;
            }
          }
        }

      }

    }

    &.panel-attitude {
      // justify-content: start;

      .slide-4-mobile__container {
        position: relative;
        max-width: 1025px;
        height: 100vh;
        max-height: 600px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-direction: column;
        padding: 0;
        background-color: #E20034;

        .panel-attitude {
          &__text-container {
            width: 100vw;
            height: -webkit-fill-available;
            position: relative;
          }

          &__attitude-uppercase {
            width: 100%;
            color: #000;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 6;
            padding: 0;

            img {
              height: 106px;
              margin: 0 auto;
            }
          }

          &__attitude-title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            width: 280px;
          }

          &__attitude-thumbs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: calc(100% - 200px);
            width: 310px;
            gap: 0;
            margin: 0 auto;
            aspect-ratio: 1/1;
            align-content: center;
            justify-items: center;

            :nth-child(1) {
              z-index: 8;
            }

            :nth-child(3) {
              z-index: 8;
            }

            img,
            video {
              width: 100% !important;
              max-width: 100%;
            }
          }

        }

      }

    }
  }


  .we-upstart__container {
    background-color: #E20034;
    position: relative;

    &__title {
      z-index: 0;
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;


      h2 {
        font-family: $font-stack-bison;
        font-size: 3em;
        line-height: 1;
        font-weight: 300;
        text-align: center;
        color: #ffffff;
        margin-top: 6px;

        @media screen and (min-width: 1025px) {
          display: none;
        }
      }

      &.hidden {
        opacity: 0;
      }
    }

  }


  @media screen and (max-width: 1024px) {

    .slide-4-desktop,
    .pin-spacer {
      display: none !important;
    }
  }

  @media screen and (min-width: 1025px) {

    .slide-4-mobile {
      display: none;
    }

    .pin-spacer {
      display: flex;
    }
  }

  .slide-6 {
    flex-direction: column;
    justify-content: center;
    background-color: #FFF9F3;
    padding: 0;

    @media screen and (max-width: 1024px) {
      padding: 60px 0;
    }

    h1 {
      font-size: 5em;
      line-height: 1;
      text-align: center;

      @media screen and (max-width: 1024px) {
        font-size: 2.4em;
      }

    }

    &__container {
      &__title {
        position: absolute;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: 50% 50%;
        bottom: 20%;
        left: -139px;

        @media screen and (max-width: 1024px) {
          position: relative;
          transform: rotate(0);
          transform-origin: unset;
          left: unset;
          text-align: center;
          bottom: 10%;
        }


        .clients_script {
          position: relative;
          top: 140px;
          left: 214px;
          z-index: 1;

          img {
            transform: rotate(90deg);
            width: 100px !important;
          }

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }

        .break-after::after {
          content: unset !important;
          white-space: unset !important;
        }

        @media screen and (max-width: 1024px) {
          .break-after::after {
            content: "\a" !important;
            white-space: pre !important;
            ;
          }
        }

      }

      &__logos {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__row-1,
        &__row-2,
        &__row-3,
        &__row-4 {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;
          height: 160px;

          &:last-child {
            margin-bottom: 0;
          }

          @media screen and (max-width: 1024px) {
            height: 90px;
            margin-bottom: 10px;
          }

          .logo-box {
            display: flex;
            justify-content: center;
            width: 220px;
            padding: 4.7rem 0;

            &.london-pride {
              img {
                width: 32%;
              }
            }

            &.lipton {
              img {
                width: 40%;
              }
            }

            &.peroni {
              padding: 2.5rem;

              img {
                width: 100%;
              }
            }

            &.pepsi {
              img {
                width: 35%;
              }
            }

            &.delonghi {
              padding: 2.5rem;

              img {
                width: 80%;
              }
            }

            &.hilton {
              padding: 2rem;
            }

            &.cleanco {
              img {
                width: 50%;
              }
            }

            &.asahi {
              img {
                width: 45%;
              }
            }

            img {
              width: 60%;
            }

            @media screen and (max-width: 1024px) {
              width: 110px;
              padding: 1rem;

              &.london-pride {
                img {
                  width: 50%;
                }
              }


              &.campari {
                img {
                  width: 100%;
                }
              }

              &.cleanco {
                img {
                  width: 70%;
                }
              }

              &.asahi {
                img {
                  width: 80%;
                }
              }


              &.lipton {
                img {
                  width: 70%;
                }
              }

              &.peroni {
                padding: 0.5rem;

                img {
                  width: 100%;
                }
              }

              &.pepsi {
                img {
                  width: 70%;
                }
              }

              &.delonghi {
                padding: 0.8rem;

                img {
                  width: 100%;
                }
              }

              &.hilton {
                padding: 1.1rem;
              }

              &:nth-child(-n+3) {
                display: flex;
              }
            }
          }
        }

        @media screen and (max-width: 1024px) {
          display: none;
        }

      }

      &__logos_mobile {
        display: none;
        flex-direction: column;
        align-items: center;

        &__row-1,
        &__row-2,
        &__row-3,
        &__row-4 {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 30px !important;
          height: 110px;

          &:last-child {
            margin-bottom: 0;
          }

          @media screen and (max-width: 1024px) {
            height: 90px;
          }

          .logo-box {
            display: flex;
            justify-content: center;
            width: 140px;
            padding: 2rem;

            img {
              width: 80%;
            }

            @media screen and (max-width: 1024px) {
              width: 120px;
              padding: 1rem;

              //display: none;

              &:nth-child(-n+3) {
                display: flex;
              }
            }
          }
        }

        @media screen and (max-width: 1024px) {
          display: flex;
        }
      }

    }

    @media screen and (max-width: 432px) {
      height: 667px;

    }
  }



  .break-after::after {
    content: "\a";
    white-space: pre;
  }
}

.wrapper {
  z-index: unset;
}

.mobile-indicator {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 1;
  width: 70px;

  img {
    width: 100%;
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.mobile-indicator[style*="display: none"] {
  opacity: 0;
  /* Fade out */
}
</style>
