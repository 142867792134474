import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { pageClass: "page-home" },
  },
  {
    path: "/our-work",
    name: "OurWorkPage",
    component: () => import("../views/OurWorkPage.vue"),
    meta: { pageClass: "page-work" },
  },
  {
    path: "/disciplines",
    name: "DisciplinesPage",
    component: () => import("../views/DisciplinesPage.vue"),
    meta: { pageClass: "page-disciplines" },
  },
  {
    path: "/our-people",
    name: "OurPeoplePage",
    component: () => import("../views/OurPeoplePage.vue"),
    meta: { pageClass: "page-people" },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPage.vue"),
    meta: { pageClass: "page-privacy" },
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: () => import("../views/CookiePage.vue"),
    meta: { pageClass: "page-cookies" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0, left: 0 }; 
    }
  },
});

export default router;
