<template>
  <div class="footer" id="contact">
    <div class="footer__branding-container">
      <div class="footer__branding-container__content">
        <v-img max-height="614" :src="require('@/assets/images/staff/Eamon.png')" alt="Employee Photo"
          class="footer-photo"></v-img>

        <h1>WE'RE A<br /> LOVELY BUNCH -</h1>
        <h1 class="align-right">WE'D LOVE TO<br /> HEAR FROM YOU!</h1>
      </div>
    </div>

    <div class="footer__details">
      <div class="footer__details-section">
        <div class="footer__details-section-coffee">
          <h3>Coffee?</h3>
          <div class="footer-copy">
            <span class="break-after">St. John's Hall, </span>
            <span class="break-after">374 North End Road </span>
            <span class="break-after">London, SW6 1LY</span>
          </div>
        </div>

        <div class="footer__details-section-chat">
          <h3>Chat?</h3>
          <div class="footer-copy">
            <a href="tel:+44 (0) 207 385 6677">+44 (0) 207 385 6677</a>
            <p>Ask to speak to Jackie</p>
            <a href="mailto:jackie@ignis.co.uk">jackie@ignis.co.uk</a>
          </div>
        </div>

        <div class="footer__details-section-join">
          <h3>Join?</h3>
          <div class="footer-copy">
            <p>
              Join our award winning team.
              Send your portfolio/CV to:
            </p>
            <a href="mailto:jackie@ignis.co.uk">jackie@ignis.co.uk</a>

          </div>
        </div>

        <div class="footer__details-section-social">
          <ul>
            <li><a href="https://www.linkedin.com/company/ignis-ltd/">
              <img :src="require('@/assets/images/linked_in.svg')" />
              </a>
            </li>
            <li><a href="https://www.tiktok.com/@ignisagency">
              <img :src="require('@/assets/images/tiktok.svg')" />

              </a>
            </li>
            <li><a href="https://www.instagram.com/ignisagency">
              <img :src="require('@/assets/images/instagram.svg')" />
              </a>
            </li>
          </ul>
          <img :src="require('@/assets/images/EOA-logo.png')" alt="EOA - Proudly employee owned" />
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
</script>


<style lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: #000;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    text-align: left;
    height: 100dvh;
  }

  a {
    color: #000;
  }

  &__branding-container {
    width: 100%;
    height: calc(100vh - 200px);
    min-height: 644px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/images/love.png');
    background-size: 650px;
    background-position: 55% 40%;
    background-repeat: no-repeat;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      background-size: 90%;
      //height: 60%;
      min-height: unset;
    }

    &__content {
      width: 960px;
      display: flex;
      flex-direction: column;
      padding: 0 30px;
      gap: 20px;
      position: relative;

      @media screen and (max-width: 768px) {
        padding: 0;

      }
    }

    .footer-photo {
      width: 460px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    h1 {
      color: #ffffff;
      text-align: left;
      font-size: 6.58rem;
      line-height: 0.775em;
      z-index: 2;

      &.align-right {
        align-self: end;
      }
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      padding: 10px 20px 0;

      h1 {
        color: #ffffff;
        text-align: left;
        font-size: 3rem;
        line-height: 0.775em;
      }

      .footer-photo {
        width: 70%;
        // position: absolute;
        // z-index: 1;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -80%);
      }

    }
  }

  &__details {
    width: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1024px) {
      width: calc(100% - 60px);
      padding: 30px 0;
      height: 40%;
    }

    &-section {
      padding: 20px;
      display: flex;
      width: 100%;
      max-width: 960px;
      gap: 20px;

      @media screen and (max-width: 768px) {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      &-coffee,
      &-chat,
      &-join,
      &-social {
        width: calc(100vw / 4);

        @media screen and (max-width: 768px) {
          width: unset;
          padding: 10px;
        }
      }

      &-social {
        ul {
          padding: 0 0 10px 0 !important;
          display: flex;
          gap: 10px;

          img {
            width: 40px;
          }

          @media screen and (max-width: 768px) {

            .v-image {
              width: 20px;
            }
          }
        }

        &>img {
          width: 150px;

          @media screen and (max-width: 768px) {
            width: 120px;
          }
        }

        @media screen and (max-width: 768px) {
          padding-top: 10px;
        }

      }

      h3 {
        font-size: 2.5em;
        line-height: 1;
        padding-bottom: 10px;

        @media screen and (max-width: 768px) {
          padding: 0;

        }
      }

      .footer-copy {
        font-family: $font-stack-body;
        font-size: 1em;
        font-weight: 300;

        .break-after {
          display: block;

          @media screen and (max-width: 768px) {
            font-size: 0.875em !important;
          }
        }

        p {
          padding: 0;
          margin-bottom: 0;
        }

        a {
          color: #000 !important;
          font-weight: 600;

          @media screen and (max-width: 768px) {
            font-size: 0.875em !important;
          }
        }

        @media screen and (max-width: 768px) {
          font-size: 0.875em !important;
        }
      }

      @media screen and (max-width: 768px) {
        h3 {
          font-size: 1.5em;
        }

        p {
          font-size: 0.875em;

        }
      }
    }

  }

  @media screen and (max-width: 432px) {
    // padding-top: 20px;
    //  height: 667px;

  }
}
</style>