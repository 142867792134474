import { createApp } from "vue";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import App from "./App.vue";
import { createGtm } from "@gtm-support/vue-gtm"; 
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faExpand,
  faPause,
  faPlay,
  faVolumeXmark,
  faVolumeHigh,
  faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";

const app = createApp(App);

app.use(
  createGtm({
    id: "GTM-W8GV88",
    enabled: false,
    debug: false,
    queryParams: {},
    compatibility: false,
    nonce: "7S00aPbloS",
    vueRouter: router,
  })
);

// ✅ Add Font Awesome icons
library.add(
  faPause,
  faPlay,
  faVolumeXmark,
  faVolumeHigh,
  faExpand,
  faCirclePlay
);
app.component("font-awesome-icon", FontAwesomeIcon);

const vuetify = createVuetify({
  components,
  directives,
})

app.use(router);
app.use(vuetify);

app.mount("#app");
