<template>
  <v-app :class="pageClass">
    <AppMenu></AppMenu>
    <v-main>
      <router-view :class="{ withMenu: menuOpen }" />
    </v-main>
    <FooterSection></FooterSection>
  </v-app>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import AppMenu from '@/components/AppMenu';
import FooterSection from '@/components/FooterSection';

const menuOpen = ref(false);
const route = useRoute();
const pageClass = computed(() => {
  return route.meta.pageClass || '';
});

</script>

<style lang="scss">
@import '@/assets/scss/style';

$panel-brands-bg-color: #E20034;
$text-light: #ffffff;
$text-dark: #000000;

@function best-text-color($bg-color, $light-color, $dark-color) {
  $lightness: lightness($bg-color);
  @return if($lightness > 50%, $dark-color, $light-color);
}

.header {
  height: 77px;
}

.page-disciplines {
  .menu a {
    color: #ffffff !important;
  }

  .burger-menu path {
    fill: #FFFFFF;
  }

  .logo path {
    fill: #FFFFFF;
  }
}

.page-our-people {
  .menu a {
    color: #E20034 !important;
  }

  .burger-menu path {
    fill: #FFFFFF;
  }

  .logo path {
    fill: #FFFFFF;
  }
}
</style>
